<template>
  <div>
    <b-modal id="modal-data-edit" ref="modal" title="Data" @show="resetModal" @hidden="resetModal" @ok="handleOk">
      <b-overlay :show="isSaving" no-wrap></b-overlay>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row class="my-1">
          <b-col sm="3">
            <label> Station code </label>
          </b-col>
          <b-col sm="9">
            <b-input-group size="sm" type="text">
              <b-form-input v-model="data.wst_code"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="3">
            <label> Date </label>
          </b-col>
          <b-col sm="9">
            <b-input-group size="sm">
              <b-form-input
                id="example-input"
                v-model="data.date"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                disabled
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker size="sm" v-model="data.date" button-only right locale="en-US"></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="3">
            <label> Time </label>
          </b-col>
          <b-col sm="9">
            <b-input-group>
              <b-form-timepicker size="sm" v-model="data.time" placeholder="Choose a time"></b-form-timepicker>
            </b-input-group>
          </b-col>
        </b-row>

        <br />
        <h5>Parameters</h5>
        <hr />
        <b-row class="my-1" v-for="param in params" :key="param.id">
          <b-col sm="3">
            <label :for="param.slug">
              {{ param.name.charAt(0).toUpperCase() + param.name.slice(1) }}
            </label>
          </b-col>
          <b-col sm="9">
            <b-input-group size="sm" :id="param.slug" type="text" :append="param.unit">
              <b-form-input v-model="data.params[param.slug]"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <!--end::Body-->
  </div>
</template>

<script>
import * as Services from '../services.js';

export default {
  name: 'data-edit-comp',
  data() {
    return {
      mode: null,
      data: {
        id: 0,
        bat_id: '',
        wst_code: '',
        depth: '',
        date: '',
        time: '',
        params: null,
      },
      params: null,
      stationCodeState: null,
      dateState: null,
      timeState: null,
      depthState: null,
      isShow: false,
      isSaving: false,
    };
  },
  mounted() {},
  methods: {
    showModal(options) {
      this.mode = options.mode;
      if (options.mode == 'edit') {
        // this.data = options.data;
        this.data.id = options.data.id;
        this.data.bat_id = options.data.bat_id;
        this.data.wst_code = options.data.wst_code;
        this.data.depth = options.data.depth;
        const datetime = options.data.date.split(' ');
        this.data.date = datetime[0];
        this.data.time = datetime[1];
        this.data.params = options.data.params;
      }
      this.getParamList();
      if (this.data.params == null || this.data.params === undefined) {
        for (const key in this.params) {
          console.log(key);
          if (Object.hasOwnProperty.call(this.params, key)) {
            const element = this.params[key];
            this.data.params.push(key, null);
          }
        }
      }
      this.$bvModal.show('modal-data-edit');
    },
    getData(id) {
      if (this.mode == 'edit') {
        this.$store
          .dispatch(Services.GET_STATIONBASE_DATA, {id: id})
          // go to which page after successfully login
          .then((res) => {
            this.data = res.data[0];
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getParamList() {
      //Get Parameter List
      this.$store
        .dispatch(Services.GET_PARAMETER_LIST)
        // go to which page after successfully login
        .then((res) => {
          if (res.status) {
            this.params = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_STATIONBASE_DATA, this.data)
        // go to which page after successfully login
        .then((res) => {
          if (res.status) {
            // this.data = res.data[0];
            this.isSaving = false;
            this.$bvToast.toast('Data saved.', {
              title: 'Successfully',
              variant: 'success',
              solid: true,
            });
            this.$parent.getDataList();
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('modal-data-edit');
            });
          } else {
            // this.data = res.data[0];
            this.isSaving = false;
            this.$bvToast.toast(res.message, {
              title: 'Error!',
              variant: 'danger',
              solid: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.isSaving = false;
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.stationCodeState = valid;
      return valid;
    },
    resetModal() {},
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      //Save Data
      this.save();
    },
  },
};
</script>

<style></style>
